import { Container } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, MutableRefObject, useEffect, useState } from 'react';
import { format } from 'date-fns';
import 'src/styles/components/event-info/Home.scss';
import { tryRequire } from 'src/utils/common';

export interface HomeProps {
    metaData: {id: string, name: string, ref: MutableRefObject<null>};
    imageFolder: string;
    eventDate: string;
    groomFirstName: string;
    brideFirstName: string;
    ourStory: string;
    city: string;
    state: string;
}


export const Home: FC<HomeProps> = observer((props: HomeProps) => {
    const { metaData, imageFolder, groomFirstName, brideFirstName, ourStory, city, state } = props;
    const [countDown, setCountDown] = useState('');
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const eventDate = new Date(props.eventDate);
    const countDownDate = eventDate.getTime();

    useEffect(() => {
        // Get today's date and time
        const now = new Date().getTime();

        // Find the distance between now and the count down date
        const distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        const d = Math.floor(distance / (1000 * 60 * 60 * 24));
        const h = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const m = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const s = Math.floor((distance % (1000 * 60)) / 1000);

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            if (distance > 0) {
                setDays(d);
                setHours(h);
                setMinutes(m);
                setSeconds(s);
            }
            setCountDown((distance > 0) ? days + "d " + hours + "h "
                + minutes + "m " + seconds + "s " : '');
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);

    }, [countDownDate, countDown, days, hours, minutes, seconds]);

    return (
        <div id={metaData.id} ref={metaData.ref}>
            <div className='home' style={{backgroundImage: `url(${tryRequire(imageFolder, 'mainImage').default})`}}>
                <div className="lbox-caption">
                    <div className="lbox-details">
                        <h1> {`${groomFirstName} & ${brideFirstName}`}</h1>
                        {countDown && <div>
                            <h2>We're getting married in</h2>
                            <div className="simply-countdown simply-countdown-one">
                                <div className="simply-section simply-days-section">
                                    <div>
                                        <span className="simply-amount">{days}</span>
                                        <span className="simply-word">days</span>
                                    </div>
                                </div>
                                <div className="simply-section simply-hours-section">
                                    <div>
                                        <span className="simply-amount">{hours}</span>
                                        <span className="simply-word">hours</span>
                                    </div>
                                </div>
                                <div className="simply-section simply-minutes-section">
                                    <div>
                                        <span className="simply-amount">{minutes}</span>
                                        <span className="simply-word">minutes</span>
                                    </div>
                                </div>
                                <div className="simply-section simply-seconds-section">
                                    <div>
                                        <span className="simply-amount">{seconds}</span>
                                        <span className="simply-word">seconds</span>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
                <div className="box">
                    <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="title-box">
                            <h2>Hello</h2>
                            <p>We invite you to celebrate our wedding on</p>
                            <h3>{format(new Date(eventDate.valueOf() + eventDate.getTimezoneOffset() * 60 * 1000), 'MMMM d, yyyy')}</h3>
                            <p>in</p>
                            <h3>{`${city}, ${state}`}</h3>
                        </div>
                    </Container>
                </div>
                {/* <!-- Start Story --> */}
                <div id="story" className="box">
                    <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="title-box">
                            <img src={tryRequire(imageFolder, 'ourStory').default} alt="our story" className="circle-image" />
                            <h2>Our Story</h2>
                            <p>{ourStory}</p>
                        </div>
                    </Container>
                </div>  
        </div>
    );
});