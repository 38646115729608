import { Button, Menu, Fade, MenuItem } from "@mui/material";
import { FC, useState, MouseEvent } from "react";
import { Ceremony } from "src/types/eventType";
import makeUrls from "src/utils/calendar-urls";
import { formatAddress } from "src/utils/event";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


const getCalenderUrls = (ceremony: Ceremony, eventName: string) => {
    const event = {
        name: eventName,
        details: null,
        location: formatAddress(ceremony),
        startsAt: ceremony.dateTime,
    };

    return makeUrls(event);
}

export interface AddToCalendarProps {
    ceremony: Ceremony
    calendarEventName: string
    className: string
}

export const AddToCalendar: FC<AddToCalendarProps> = (props: AddToCalendarProps) => {
    const urls = getCalenderUrls(props.ceremony, props.calendarEventName);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Button
            className={props.className}
                variant="contained"
                size="small"
                id="fade-button"
                aria-controls="fade-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            >
                Add to calendar
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem component="a" href={urls.ics} download target="_blank" rel="noopener noreferrer">Apple Calendar/ Outlook</MenuItem>
                <MenuItem component="a" href={urls.google} download target="_blank" rel="noopener noreferrer">Google</MenuItem>
                <MenuItem component="a" href={urls.outlook} download target="_blank" rel="noopener noreferrer">Outlook Web App</MenuItem>
                <MenuItem component="a" href={urls.yahoo} download target="_blank" rel="noopener noreferrer">Yahoo</MenuItem>
            </Menu>
        </>
    );
}