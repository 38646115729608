import PageLayout from 'src/components/layout/public/PageLayout';
import { Gallery, Home, Ceremonies, LiveLinks } from 'src/components/event-info';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useEventStore } from 'src/store/root-store';
import { getMainCeremony } from 'src/utils/event';

import 'src/styles/pages/EventInfo.scss';


const getDimensions = (ele: any) => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = (ele: any) => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};


export const EventInfo: FC = () => {
  const eventStore = useEventStore();
  const event = eventStore.event;

  const homeRef = useRef(null);
  const eventRef = useRef(null);
  const galleryRef = useRef(null);  
  const linksRef = useRef(null);
  const headerRef = useRef(null);


  const navMenuOptions = useMemo(() => {
    let menuOptions = [
      {
        name: 'Home',
        id: 'home',
        ref: homeRef
      },
      {
        name: 'Events',
        id: 'events',
        ref: eventRef
      },
      {
        name: 'Gallery',
        id: 'gallery',
        ref: galleryRef
      },
      {
        name: 'Live Links',
        id: 'links',
        ref: linksRef
      }
      // {
      //   name: 'Best Wishes',
      //   id: 'wishes'
      // },
      // {
      //   name: 'RSVP',
      //   id: 'rsvp'
      // }
    ];

    if (!event?.hasLiveLinks) {
      return menuOptions.filter(item => item.name !== 'Live Links');
    }

    return menuOptions;
  }, [homeRef, eventRef, galleryRef, linksRef, event]);

  const [visibleSectionName, setVisibleSectionName] = useState(navMenuOptions[0].name);

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition = window.scrollY + headerHeight;

      const selected = navMenuOptions.find(({ ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }

        return false;
      });

      if (selected && selected.name !== visibleSectionName) {
        setVisibleSectionName(selected.name);
      } else if (!selected && visibleSectionName) {
        setVisibleSectionName(navMenuOptions[0].name);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSectionName, navMenuOptions]);

    if (event === undefined) {
    return null;
  }
  const mainCeremony = getMainCeremony(event.ceremonies)

  return (
    <PageLayout 
    headerRef={headerRef} 
    menuList={navMenuOptions} 
    selectedMenu={visibleSectionName} 
    handleNavChange={scrollTo} 
    event={event}
>

      <Home
        metaData={navMenuOptions[0]}
        imageFolder={event.imageFolder}
        eventDate={mainCeremony.dateTime}
        groomFirstName={event.groomFirstName}
        brideFirstName={event.brideFirstName}
        ourStory={event.ourStory}
        city={mainCeremony.city}
        state={mainCeremony.state} />

      <Ceremonies metaData={navMenuOptions[1]} className="box" ceremonies={event.ceremonies} mainEventName={event.name} />
      <Gallery metaData={navMenuOptions[2]} videoUrls={event.videos} photoAlbumLinks={event.photoAlbums} />
      {event?.hasLiveLinks && <LiveLinks metaData={navMenuOptions[3]} videoUrls={event.videos} /> }
    </PageLayout>
  );
};