import {Event} from 'src/types/eventType'

const EVENT_DATA = [
   {
    eventId: "kanagu",
    type: "wedding",
    name: "Karthik & Nagalakshmi",
    greeting: "Hope you can make it",
    imageFolder: "kanagu",
    hashTags: [],
    hasLiveLinks: true,
    ceremonies: [
        {
            dateTime: "Thu Nov 10 2021 10:00:00 GMT+0530 (Indian Standard Time)",
            type: "MAIN_CEREMONY",
            name: "Wedding",
            addressLine1: "No 209 Ramakrishna Mutt Layout",
            addressLine2: "K G Nagar",
            city: "Mysore",
            state: "Karnataka",
            country: "India",
            postalCode: "560019",
            googleMapsLink: "https://www.google.com/maps/place/Pai+Vista+Convention+Hall,+Jayanagar/@12.937926,77.5787204,15z/data=!4m2!3m1!1s0x0:0x4cad936ca6b56d89?sa=X&ved=2ahUKEwiGhvfo5rz0AhUxRDABHYDSDosQ_BJ6BAhCEAU",
            description: "Optional"
        },
        {
            dateTime: "Thu Nov 9 2021 19:00:00 GMT+0530 (Indian Standard Time)",
            type: "OTHER",
            name: "Reception",
            addressLine1: "No 209 Ramakrishna Mutt Layout",
            addressLine2: "K G Nagar",
            city: "Mysore",
            state: "Karnataka",
            country: "India",
            postalCode: "560019",
            googleMapsLink: "https://www.google.com/maps/place/Pai+Vista+Convention+Hall,+Jayanagar/@12.937926,77.5787204,15z/data=!4m2!3m1!1s0x0:0x4cad936ca6b56d89?sa=X&ved=2ahUKEwiGhvfo5rz0AhUxRDABHYDSDosQ_BJ6BAhCEAU",
            description: "Optional"
        },
        {
            dateTime:"Thu Nov 9 2021 15:00:00 GMT+0530 (Indian Standard Time)",
            type: "MAIN_CEREMONY",
            name: "Engagement",
            addressLine1: "No 209 Ramakrishna Mutt Layout",
            addressLine2: "K G Nagar",
            city: "Mysore",
            state: "Karnataka",
            country: "India",
            postalCode: "560019",
            googleMapsLink: "https://www.google.com/maps/place/Pai+Vista+Convention+Hall,+Jayanagar/@12.937926,77.5787204,15z/data=!4m2!3m1!1s0x0:0x4cad936ca6b56d89?sa=X&ved=2ahUKEwiGhvfo5rz0AhUxRDABHYDSDosQ_BJ6BAhCEAU",
            description: "Optional"
        }
    ],
    videos: [
        {
            label: 'Wedding', 
            url: 'https://www.youtube.com/watch?v=4n6pPtHoiwU',
            isLiveLink: true
        }
    ],
    photoAlbums: [
        {
            label: 'Photos Album', 
            url: 'https://photos.app.goo.gl/awQpy8xjZGQiCHjx6'
        }
    ],
    brideFirstName: "Nagalakshmi",
    groomFirstName: "Karthik",
    ourStory: "This year was full of surprises, we met June 2021. Now we are planning to build our future together.",
    },
    {
        eventId: "shashikoki",
        type: "wedding",
        name: "Shashikiran & Kokila",
        greeting: "You are cordially invited to our special day",
        imageFolder: "shashikoki",
        hashTags: ["kokiran, shashikoki"],
        ceremonies: [
            {
                dateTime: "Thu Jan 27 2022 10:15:00 GMT+0530 (Indian Standard Time)",
                type: "MAIN_CEREMONY",
                name: "Wedding",
                addressLine1: "Pattalamma Temple Rd, near South End Circle Metro",
                addressLine2: "Basavanagudi",
                city: "Bengaluru",
                state: "Karnataka",
                country: "India",
                postalCode: "560004",
                description: "This is the main event which consists of multiple rituals. Some of them are Kashiyatra(Bride's father convincing the groom to get married by explaining the superiority of married life to an ascetic life), Gowri puja(bride worships goddess Gowri as she is the symbol of fertility and motherhood), Sumuhrutham(Couple is asked to place a mixture of cumin seeds and jaggery on each other's head, followed by tying of Mangalsutram or Thaali), Akshatharopane(Also called as Thalambralu, the bride and groom pour colored rice on each other’s head like a shower), Panigrahana(Clasping the hands), Saptapadi(The Seven steps)",
                note: "Be ready, It will be a long day.",
                googleMapsLink: "https://g.page/Pai_Convetion_Hall?share"
            },
            {
                dateTime: "Thu Jan 27 2022 05:00:00 GMT+0530 (Indian Standard Time)",
                type: "OTHER",
                name: "Upanayanam",
                addressLine1: "Pattalamma Temple Rd, near South End Circle Metro",
                addressLine2: "Basavanagudi",
                city: "Bengaluru",
                state: "Karnataka",
                country: "India",
                postalCode: "560004",
                description: "This ritual signifies leading the groom to the vedic teacher - the guru who provides an additional eye of wisdom and knowledge, this is a sacrament of beginning of education. The Brahma stands for vedas as also for Gayatri mantra. This is taught to the novice groom. A sacred thread comprising three strands of strings along with a knot put on to wear, enchanting mantras called Yagnopaveetha Dharanam",
                googleMapsLink: "https://g.page/Pai_Convetion_Hall?share"
            },
            {
                dateTime: "Thu Jan 26 2022 11:00:00 GMT+0530 (Indian Standard Time)",
                type: "OTHER",
                name: "Devathakaryam",
                addressLine1: "Pattalamma Temple Rd, near South End Circle Metro",
                addressLine2: "Basavanagudi",
                city: "Bengaluru",
                state: "Karnataka",
                country: "India",
                postalCode: "560004",
                description: "After entering the marriage hall, this is the first event where we pray god and seek his blessings for all wedding rituals to be performed.",
                googleMapsLink: "https://g.page/Pai_Convetion_Hall?share"
            },
            {
                dateTime: "Thu Jan 26 2022 18:30:00 GMT+0530 (Indian Standard Time)",
                type: "OTHER",
                name: "Reception",
                addressLine1: "Pattalamma Temple Rd, near South End Circle Metro",
                addressLine2: "Basavanagudi",
                city: "Bengaluru",
                state: "Karnataka",
                country: "India",
                postalCode: "560004",
                description: "Reception is the first public appearance of the couple. It does not include any rituals, it serves as an opportunity for the bride to know the acquaintances and associates of the groom's family and vice versa.",
                note: "Dress up well, as all eyes might be on you!",
                googleMapsLink: "https://g.page/Pai_Convetion_Hall?share"
            },
            {
                dateTime: "Sun Jan 30 2022 08:00:00 GMT+0530 (Indian Standard Time)",
                type: "OTHER",
                name: "Satyanarayana Swamy Puja",
                addressLine1: "No 209, Ramakrishna Mutt Layout",
                addressLine2: "K G Nagar",
                city: "Bengaluru",
                state: "Karnataka",
                country: "India",
                postalCode: "560019",
                description: "A puja is held in the honor of the newly married couple. A priest comes and performs it, and the newlyweds perform it as per his instructions. This is the first venture they undertake as a husband and wife; they seek blessings for a prosperous and happy married life.",
                googleMapsLink: "https://goo.gl/maps/JTuSQ71EUu4d9urCA"
            },
            {
                dateTime: "Fri Jan 28 2022 14:00:00 GMT+0530 (Indian Standard Time)",
                type: "OTHER",
                name: "Turmeric Shastram",
                addressLine1: "No 209, Ramakrishna Mutt Layout",
                addressLine2: "K G Nagar",
                city: "Bengaluru",
                state: "Karnataka",
                country: "India",
                postalCode: "560019",
                description: "It is like a post marriage haldi(turmeric) ceremony, where the bride and groom will apply turmeric paste on each others face. Some cousins and relatives will also apply the paste on couples' face, in turn the couple will also apply the paste on relatives more like Holi. After this, the relatives will make the newly married couple play some games such as throw ball(with ball made of garlands used for muhurtham), splashing water and etc(time permitting).",
                note: "Yellow attire is preferred.",
                googleMapsLink: "https://goo.gl/maps/JTuSQ71EUu4d9urCA"
            }
        ],
        videos: [
            {
                label: 'Reception', 
                url: 'https://www.youtube.com/watch?v=fletmu61FF8',
                isLiveLink: true
            },
            {
                label: 'Wedding', 
                url: 'https://www.youtube.com/watch?v=kpdwG_m-mxE',
                isLiveLink: true
            }
        ],
        photoAlbums: [
            {
                label: 'Shared Album', 
                url: 'https://photos.app.goo.gl/raKahDkxHEosZcVbA',
                description: 'Showcase your photography skills by sharing your clicks.',
            }
        ],
        hasLiveLinks: true,
        brideFirstName: "Kokila",
        groomFirstName: "Shashikiran",
        ourStory: "It started like a regular arranged marriage when parents asked us to talk as our horoscope matched. We met on Febraury 6, 2021 just when things were getting back to normal after 1st wave of COVID-19. Our first video call set the tone for our relationship, everything seemed positive since then. \n\nAnd now within an year, few meetings and countless video calls later - we’re thrilled to tie the knot and build a future together.",
    },
];

export default class EventsApi {

    async getEvent(id: string): Promise<Event> {
        const event = EVENT_DATA.filter(event => event.eventId === id);
        return Promise.resolve(event[0]);
    }
}