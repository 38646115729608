import { action, observable } from "mobx";
import EventsApi from "src/service/event";
import { Event } from "src/types/eventType";

export default class EventStore {
    private readonly eventsApi: EventsApi;
   
    @observable event?: Event;

    constructor() {
        this.eventsApi = new EventsApi();
    }

    @action
    public setMainEvent(event: Event) {
        this.event = event;
    }

    public async loadMainEvent(eventId: string): Promise<void> {
        try {
            const event = await this.eventsApi.getEvent(eventId);
            this.setMainEvent(event);
        } catch (e: any) {
            console.error(e);
        }
    }
}