import { RouterState, RouterStore } from "mobx-state-router"
import { RootStore } from "src/store/root-store"
import { Route } from "./routes";

export const handleHomeRoute = async (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<RouterState> => {
    let eventId = toState.params.eventId;
    if (!eventId) {
        eventId = "shashikoki";
    }
    return routerStore.goTo(Route.EVENT_INFO, {params: {eventId: eventId}});
}

export const handleEventRoute = async (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
    const { rootStore } = routerStore.options as {rootStore: RootStore};
    const { eventStore } = rootStore;

    let eventId = toState.params.eventId;

    if (!eventId) {
        eventId = "shashikoki";
    }

    await eventStore.loadMainEvent(eventId);
}