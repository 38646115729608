import { handleEventRoute, handleHomeRoute } from "./route-handler";

export class Route {
    static HOME = 'home';
    static EVENT_INFO = 'eventInfo';
    static NOT_FOUND = 'notFound';
}

export const routes = [
    {
        name: 'home',
        pattern: '/',
        onEnter: handleHomeRoute
    },
    {
        name: 'eventInfo',
        pattern: '/event/:eventId',
        onEnter: handleEventRoute
    },
    {
        name: 'notFound',
        pattern: '/not-found',
    },
];