import { Card, CardActionArea, CardMedia, CardContent, Typography } from "@mui/material";
import { FC } from "react";

export interface ImageCardProps {
    url: string
    label: string
    description?: string
}

export const ImageCard: FC<ImageCardProps> = (props: ImageCardProps) => {

    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardActionArea href={props.url} target="_blank">
                <CardMedia
                    component="img"
                    image="https://upload.wikimedia.org/wikipedia/commons/6/60/Google_Photos_icon_%282015-2020%29.svg"
                    alt="Google photos icon"
                    height="225"
                    title="Photos link"
                />
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" align="center" component="h2">
                        {props.label}
                    </Typography>
                    {props.description && <Typography variant="h6" align="center">
                        {props.description}
                    </Typography>}
                </CardContent>
            </CardActionArea>
        </Card>


    );
}