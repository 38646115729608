import { Container, Grid, Button } from "@mui/material";
import { FC, MutableRefObject } from "react";
import { Ceremony } from "src/types/eventType";
import { format, compareAsc } from 'date-fns';
import { AddToCalendar } from "src/components/common/AddToCalender";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import 'src/styles/components/event-info/Ceremonies.scss';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

export interface CeremoniesProps {
    metaData: {id: string, name: string, ref: MutableRefObject<null>};
    className: string
    mainEventName: string
    ceremonies: Ceremony[]
}

const ceremoniesSortAscending = (ceremonies: Ceremony[]) => (ceremonies).sort((a: Ceremony, b: Ceremony) => compareAsc(new Date(a.dateTime), new Date(b.dateTime)));


export const Ceremonies: FC<CeremoniesProps> = (props: CeremoniesProps) => {

    return (
        <div id={props.metaData.id} className={props.className} ref={props.metaData.ref}>

            <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                <div className="title-box">
                    <h2>Events</h2>
                </div>
            </Container>
            <div className="ceremonies">
                <div className="overlay">
                    <Container maxWidth="sm" sx={{ p: 4 }}>
                        <Grid container spacing={5}>
                            {ceremoniesSortAscending(props.ceremonies).map((value) => {
                                const dateTime = new Date(value.dateTime);
                                return (
                                    <Grid key={value.name} item xs={12}>
                                        <div className="event-wrap animate-box">
                                            <h3> {value.name}</h3>
                                            <div className="event-col-container">
                                                <div className="event-col">
                                                    <AccessTimeIcon className="i" />
                                                    <span> {format(dateTime, 'KK:mm aa')}</span>
                                                </div>
                                                <div className="event-col">
                                                    <CalendarTodayIcon className="i" />
                                                    <span> {format(dateTime, 'MMMM do, yyyy')}</span>
                                                </div>
                                            </div>

                                            {value.description && <div className="description">{value.description}</div>}

                                            {value.note && <div className="note">{`Note: ${value.note}`}</div>}
                                            <div className="buttons">
                                                <AddToCalendar className="button" ceremony={value} calendarEventName={props.mainEventName + ' - ' + value.name} />
                                                <Button
                                                    className="button"
                                                    variant="contained" size="small"
                                                    endIcon={<KeyboardArrowRightIcon />}
                                                    href={value.googleMapsLink}
                                                    target="_blank"
                                                >See Location</Button>
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Container>
                </div>
            </div>
        </div>
    );
};
