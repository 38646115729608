import { Button, Container, Grid, Tooltip } from '@mui/material';
import { FC, MutableRefObject, useState } from 'react';
import { IframeCard } from '../common/IframeCard';
import { ImageCard } from '../common/ImageCard';

export interface GalleryProps {
    metaData: {id: string, name: string, ref: MutableRefObject<null>};
    photoAlbumLinks: { label: string, url: string, description?: string }[];
    videoUrls: { label: string, url: string, isLiveLink?: boolean, description?: string }[];
}

export const Gallery: FC<GalleryProps> = (props: GalleryProps) => {

    const [copyText, setCopyText] = useState('Click to copy');
    const [open, setOpen] = useState(false);

    const handleButtonClick = () => {
        navigator.clipboard.writeText('#kokiran')
        setCopyText('Copied!');
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setCopyText('Click to copy');
        setOpen(true);
    };

    return (
        <div id={props.metaData.id} ref={props.metaData.ref}>
            <div className="box">
                <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="title-box">
                        <h2>Gallery</h2>
                        <p>Share your love by tagging us with
                            <Button sx={{ font: 'inherit', color: 'inherit' }} variant="text" size="small" onClick={handleButtonClick}>
                                <Tooltip open={open} onClose={handleClose} onOpen={handleOpen} title={copyText} placement="top" arrow>
                                    <em>#kokiran</em>
                                </Tooltip>
                            </Button>
                            in social media</p>
                    </div>
                </Container>

                <Container maxWidth="md">
                    <Grid
                        container
                        spacing={4}
                        alignItems="center"
                        justifyContent="center">
                        {props.photoAlbumLinks
                            .map((value) => (
                                <Grid key={value.label} item xs={12} sm={6}>
                                    <ImageCard url={value.url} label={value.label} description={value.description} />
                                </Grid>
                            ))}
                        {props.videoUrls
                            .filter((item) => item.isLiveLink === false)
                            .map((value) => {
                                const url = value.url.replace("watch?v=", "embed/");
                                return (<Grid key={value.label} item xs={12} sm={6}>
                                    <IframeCard url={url} label={value.label} description={value.description} />
                                </Grid>
                                )
                            })}
                    </Grid>
                </Container>
            </div>
        </div>
    );
};
