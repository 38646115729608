import { observable } from 'mobx';
import {
    browserHistory,
    createRouterState,
    HistoryAdapter,
    RouterStore,
} from 'mobx-state-router';
import { createContext, useContext } from 'react';
import { routes } from 'src/router-config/routes';
import EventStore from './event-store';

const notFound = createRouterState('notFound');


export class RootStore {
    @observable readonly eventStore: EventStore;
    readonly router: RouterStore;

    constructor(eventStore?: EventStore) {
        this.eventStore = eventStore ?? new EventStore(); 
        this.router = this.initRouter();
    }

    private initRouter() {
        const routerStore = new RouterStore(routes, notFound, {rootStore: this});
    
        // Observe history changes
        const historyAdapter = new HistoryAdapter(routerStore, browserHistory);
        historyAdapter.observeRouterStateChanges();
    
        return routerStore;
    }
}

export const RootStoreContext = createContext(new RootStore());
export const useRootStore = (): RootStore => useContext(RootStoreContext);
export const useRouter = (): RouterStore => useRootStore().router;
export const useEventStore = (): EventStore => useRootStore().eventStore;

export default RootStoreContext;