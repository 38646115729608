import { Card, CardMedia, CardContent, Typography } from "@mui/material";
import { FC } from "react";

export interface IframeCardProps {
    url: string
    label: string
    description?: string
}

export const IframeCard: FC<IframeCardProps> = (props: IframeCardProps) => {

    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardMedia
                component="iframe"
                image={props.url}
                height="250"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="Youtube video player"
                frameBorder="0"
                allowFullScreen
            />

            <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" align="center" component="h2">
                    {props.label}
                </Typography>
                {props.description && <Typography variant="h6" align="center">
                    {props.description}
                </Typography>}
            </CardContent>
        </Card>


    );
}