import { Ceremony, MAIN_CEREMONY } from "src/types/eventType";

export const getMainCeremony = (ceremonies: Ceremony[]): Ceremony => {
    for (let ceremony of ceremonies) {
        if (ceremony.type === MAIN_CEREMONY) {
            return ceremony
        }
    }

    return ceremonies[0];
}

export const formatAddress = (ceremony: Ceremony) => `${ceremony.addressLine1}\n${ceremony.addressLine2 ? `${ceremony.addressLine2}` : ''}\n${ceremony.city}, ${ceremony.state}, ${ceremony.country}\n${ceremony.postalCode}`.trim();
