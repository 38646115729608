export const tryRequire = (imageFolder: any, name: string) => {
    try {
     return require(`src/assets/images/${imageFolder}/${name}.jpeg`);
    } catch (err) {
        try {
            return require(`src/assets/images/${imageFolder}/${name}.jpg`);
        } catch (err) {
            return null;
       }
    }
  };