import { RouterContext, RouterView } from 'mobx-state-router';
import { useRootStore } from 'src/store/root-store';
import { viewMap } from 'src/router-config/viewMap';
import { FunctionComponent } from 'react';
import { observer } from 'mobx-react';


const App: FunctionComponent = observer(() => {
  const {router} = useRootStore(); 
  return (
    <RouterContext.Provider value={router}>
      <RouterView viewMap={viewMap} />
    </RouterContext.Provider>
  );
});

export default App;
