
export const MAIN_CEREMONY = 'MAIN_CEREMONY';
export const OTHER = 'OTHER';

export interface Link {
    readonly label: string;
    readonly url: string;
}

export interface Ceremony {
    readonly dateTime: string;
    readonly type: string;
    readonly name: string;
    readonly addressLine1: string;
    readonly addressLine2: string;
    readonly city: string;
    readonly state: string;
    readonly country: string;
    readonly postalCode: string;
    readonly description?: string;
    readonly note?: string;
    readonly googleMapsLink: string;
}


export interface Event {
    readonly ourStory: string;
    readonly eventId: string;
    readonly greeting: string;
    readonly type: string;
    readonly name: string;
    readonly hashTags: string[];
    readonly hasLiveLinks: boolean;
    readonly imageFolder: string;
    readonly ceremonies: Ceremony[];
    readonly videos: Link[];
    readonly photoAlbums: Link[];
    readonly brideFirstName: string;
    readonly groomFirstName: string;
}
