import { Container, Grid } from '@mui/material';
import { FC, MutableRefObject } from 'react';
import { IframeCard } from '../common/IframeCard';

export interface LiveLinksProps {
    metaData: { id: string, name: string, ref: MutableRefObject<null> };
    videoUrls: { label: string, url: string, isLiveLink?: boolean, description?: string }[];
}

export const LiveLinks: FC<LiveLinksProps> = (props: LiveLinksProps) => {

    const liveLinks = props.videoUrls
        .filter((item) => item.isLiveLink === true);

    return (
        <div id={props.metaData.id} ref={props.metaData.ref}>

            <div className="box">
                <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="title-box">
                        <h2>Live Links</h2>
                        <p>For those who couldn't join us in person</p>

                        {liveLinks.length <= 0 && <h3>Live links coming soon...</h3>}
                    </div>
                </Container>

                {liveLinks.length > 0 &&
                    <Container maxWidth="md">
                        <Grid
                            container
                            spacing={4}
                            alignItems="center"
                            justifyContent="center">
                            {props.videoUrls
                                .filter((item) => item.isLiveLink === true)
                                .map((value) => {
                                    const url = value.url.replace("watch?v=", "embed/");
                                    return (<Grid key={value.label} item xs={12} sm={6}>
                                        <IframeCard url={url} label={value.label} description={value.description} />
                                    </Grid>
                                    )
                                })}
                        </Grid>
                    </Container>
                }
            </div>
        </div>
    );
};
