import { Container, Divider, Drawer, IconButton, styled } from '@mui/material';
import { FC, MutableRefObject, ReactNode, useState } from 'react';
import 'src/styles/components/layout/public/PageLayout.scss';
import logo from 'src/assets/images/kokiran.png';
import { Helmet } from "react-helmet";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Event } from "src/types/eventType";

export interface PageLayoutProps {
  headerRef: MutableRefObject<null>;
  children: ReactNode;
  menuList: { name: string; id: string; ref: MutableRefObject<null> }[];
  selectedMenu: any;
  handleNavChange: any;
  event: Event
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


export const PageLayout: FC<PageLayoutProps> = (props: PageLayoutProps) => {
  const { event, selectedMenu, handleNavChange, menuList, children, headerRef } = props;
  const { name, greeting, groomFirstName, brideFirstName, type, hashTags } = event;


  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className="public-app-main">
      <Helmet>
        <title>{`${name} - KoKiran`}</title>
        <meta id="meta-description" name="description" content={greeting} />
        <meta name="keywords" content={`${groomFirstName}, ${brideFirstName}, ${type}, ${hashTags.toString()}`} />
        <meta name="author" content="kokiran.com" />
      </Helmet>
      <header className="fixed-menu" ref={headerRef}>
        <nav>
          <Container sx={{ display: 'flex' }}>
            <a className="navbar-brand" href="/#">
              <span>{type}</span>
           </a>
            <div className="menu-options" id="navbar-wd">
              <IconButton
                className='hide-large-screen'
                sx={{
                  border: '1px solid #61D2B4',
                  color: "#61D2B4",
                  borderRadius: '0',
                  margin: '16px 15px',
                  padding: '10px 10px',
                  transition: 'all 0.2s linear'
                }}
                size="large"
                aria-label="Menu options"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
              <ul className="navbar-nav hide-mobile">
                {menuList.map((value, index) => (
                  <li key={index}>
                    <button className={`${selectedMenu === value.name ? 'active' : ''}`}
                    onClick={() => handleNavChange(value.ref.current)}
                  >{value.name}</button></li>
                ))}
              </ul>
            </div>
          </Container>
        </nav>
      </header>
      <Drawer
        sx={{
          width: '100%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '100%',
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <ul className="navbar-nav flex-column">
          {menuList.map((value, index) => (
            <li key={index}><button className={`nav-link ${selectedMenu === value.name ? 'active' : ''}`} 
            onClick={() => { handleNavChange(value.ref.current); handleDrawerClose(); }}>{value.name}</button></li>
          ))}
        </ul>
      </Drawer>

      <div className="app-content">
        <main>
          <article>
            {children}
          </article>
        </main>

        <footer>
          <div>
            <small>
              {'© '}{new Date().getFullYear()}{' '}kokiran.com. All Rights Reserved.
            </small>
          </div>
          <div>
            <a href="/">
              <img src={logo} alt="Home Logo" />
            </a>
          </div>
          <div>
            <small>
              Made with love by Shashikiran.
            </small>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default PageLayout;
